import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Section, Strong, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdArrowDownward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Главная | AutoAce Car Rentals
			</title>
			<meta name={"description"} content={"Ваше путешествие - наш приоритет"} />
			<meta property={"og:title"} content={"Главная | AutoAce Car Rentals"} />
			<meta property={"og:description"} content={"Ваше путешествие - наш приоритет"} />
			<meta property={"og:image"} content={"https://zephyriumparadise.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://zephyriumparadise.com/img/346243634.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://zephyriumparadise.com/img/346243634.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://zephyriumparadise.com/img/346243634.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://zephyriumparadise.com/img/346243634.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://zephyriumparadise.com/img/346243634.png"} />
			<meta name={"msapplication-TileImage"} content={"https://zephyriumparadise.com/img/346243634.png"} />
			<meta name={"msapplication-TileColor"} content={"https://zephyriumparadise.com/img/346243634.png"} />
		</Helmet>
		<Components.Header />
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://zephyriumparadise.com/img/1.jpg) center/cover"
			padding="120px 0 50px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Box margin="-16px -16px -16px -16px" display="flex">
				<Box display="flex" padding="16px 16px 16px 16px" width="75%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text
							color="--lightD2"
							letter-spacing="1px"
							text-transform="uppercase"
							margin="0"
							font="--headline1"
						>
							AutoAce Car Rentals
						</Text>
						<Text font="--headline4" margin="10px 0">
						Добро пожаловать в AutoAce Car Rentals, где ваш комфорт, удобство и удовлетворение являются нашими главными приоритетами. Отправляетесь ли вы в деловую поездку, на семейный отдых или просто нуждаетесь в надежном автомобиле для ежедневных поездок, мы готовы предоставить вам исключительный сервис и разнообразный парк автомобилей, чтобы удовлетворить все ваши транспортные потребности.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box text-align="center" margin="296px 0 0 0">
				<Text margin="8px 0" text-transform="uppercase">
					Больше про нас 
				</Text>
				<Icon category="md" margin="0 auto" icon={MdArrowDownward} />
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="flex-start"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline2"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Почему стоит выбрать AutoAce?
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--grey" font="--lead" lg-text-align="center">
				Выбирая AutoAce Car Rentals, вы выбираете превосходство, надежность и беспрецедентное обслуживание клиентов. Вот несколько причин, по которым мы выделяемся на фоне других:

					<br />
					<br />
					<Strong>
					Широкий выбор автомобилей: {" "}
					</Strong>
					 От экономичных вариантов до роскошных моделей - у нас найдется автомобиль для любого случая и бюджета.

					<Strong>
						{" "}
					</Strong>
					<br />
					<Strong>
					Прозрачное ценообразование: {" "}
					</Strong>
					Никаких скрытых платежей или сюрпризов - наши цены просты и конкурентоспособны.
					<br />
					<Strong>
					Круглосуточная поддержка клиентов: {" "}
					</Strong>
					Наша команда работает круглосуточно, чтобы помочь вам с любыми вопросами и проблемами.
					<br />
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://zephyriumparadise.com/img/2.jpg"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-8" background="--color-primary">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					width="100%"
				>
					<Image
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
						bottom="0px"
						min-height="100%"
						src="https://zephyriumparadise.com/img/3.jpg"
						object-fit="cover"
						width="100%"
					/>
				</Box>
			</Box>
				<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="flex-start"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--light"
						font="--headline2"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						О компании AutoAce
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--light" font="--lead" lg-text-align="center">
				В компании AutoAce Car Rentals мы понимаем, что аренда автомобиля должна быть легким и приятным опытом. Наша приверженность качеству и обслуживанию клиентов отличает нас от конкурентов. Благодаря широкому выбору хорошо отремонтированных автомобилей, от компактных до роскошных внедорожников, мы гарантируем, что вы найдете идеальный вариант, соответствующий вашим требованиям. Наша команда неустанно работает над тем, чтобы обеспечить беспроблемный процесс аренды, позволяя вам сосредоточиться на том, что действительно важно - на вашем путешествии.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
			Готовы к AutoAce?
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			Почувствуйте разницу с компанией AutoAce Car Rentals. Мы стремимся предоставить вам услуги аренды, которые превзойдут ваши ожидания. Широкий выбор автомобилей в сочетании с нашей приверженностью к удовлетворению потребностей клиентов гарантирует, что вы получите незабываемое и приятное путешествие.
			</Text>
			<Button
				font="--lead"
				margin="20px"
				type="link"
				text-decoration-line="initial"
				href="/contact"
			>
				Связаться с нами
			</Button>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652f9cc5dbe26f0020fd00a7"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});